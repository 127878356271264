import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "status": "draft",
  "path": "/the-common-dog/",
  "heroImage": "dog.jpg",
  "title": "The Common Dog",
  "description": "A glimpse into the mind of a dog",
  "date": "2006-03-05T00:00:00.000Z",
  "tags": ["dogs", "personal"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The domestic dog (Canis lupus familiaris when considered a subspecies of the wolf or Canis familiaris when considered a distinct species) is a member of the genus Canis (canines), which forms part of the wolf-like canids, and is the most widely abundant terrestrial carnivore.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.796875%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQL/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAABxdnQaiwp/8QAGRAAAwEBAQAAAAAAAAAAAAAAAQIDEQQS/9oACAEBAAEFAmpjC52XuiLNGYwms+cBZf/EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/Aaf/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwGI/8QAHBAAAgICAwAAAAAAAAAAAAAAAAECERIxIWGx/9oACAEBAAY/Aq2xurXhkok01pkkr57Fif/EAB0QAAICAQUAAAAAAAAAAAAAAAEhABExQVFhgaH/2gAIAQEAAT8hJjYV7KSS6LmJhROhloNAcAJQHug+CgXP/9oADAMBAAIAAwAAABCL3//EABcRAQEBAQAAAAAAAAAAAAAAAAEAIUH/2gAIAQMBAT8QeJUy/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAh/9oACAECAQE/ECAdv//EAB8QAQEAAQIHAAAAAAAAAAAAAAERACExQVFhceHw8f/aAAgBAQABPxCqgwQoKHFjWC2A9kvd8nWQHR54ngXbEJcADSm9nzKtcxV1vjP/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Dogs",
          "title": "Dogs",
          "src": "/static/75a781cfb51c556872744d05712773bc/72e01/dogs.jpg",
          "srcSet": ["/static/75a781cfb51c556872744d05712773bc/e4a55/dogs.jpg 256w", "/static/75a781cfb51c556872744d05712773bc/36dd4/dogs.jpg 512w", "/static/75a781cfb51c556872744d05712773bc/72e01/dogs.jpg 1024w", "/static/75a781cfb51c556872744d05712773bc/e5166/dogs.jpg 1200w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`The dog and the extant gray wolf are sister taxa as modern wolves are not closely related to the wolves that were first domesticated, which implies that the direct ancestor of the dog is extinct. The dog was the first species to be domesticated and has been selectively bred over millennia for various behaviors, sensory capabilities, and physical attributes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      